document.addEventListener("turbolinks:load", () => {
	console.log('hola desde inventory_movements.js');

	let code_key_field = document.getElementById("concept_code_key_field");
	let name_linked_to_field = document.getElementById("name_linked_to_field");
	
	if (code_key_field) {
		var route_path_data_products = document.getElementById("search_products_input").dataset.route;
		let changeConceptMovement = () => {
			//console.log(code_key_field.value);
			let concept_type_field = document.getElementById("concept_type_field");
			let old_concept_type = concept_type_field.value;
			let concept_description_field = document.getElementById("concept_description_field");
			//campos para seleccionar si esta ligado a cliente o provedoor
			let linked_to_fields = document.querySelectorAll("[data-type='linked_to_field']");

			//campo de ligado a 
			let linked_to_field = document.getElementById("linked_to_field");

			//console.log(concept_type_field)
			route_path_invent_mov_conc = code_key_field.dataset.route;
			//cuando carga la pagina inicializar
			// concept_type_field.value = "";
			// concept_description_field.value = "";
			linked_to_fields.forEach(elem => elem.style.display = "none");
			// hacer la peticion al servidor par encotrar el concepto por la clave
			fetch("/"+route_path_invent_mov_conc+"/get_by_key_code.json?key_code="+code_key_field.value)
			.then(resp => resp.json())
			.then(resp => {
				// console.log(resp);
				if (resp && resp.inventory_movements_concept) {
					concept_type_field.value = resp.inventory_movements_concept.concept_type;
					$(concept_type_field).trigger("change")
					concept_description_field.value = resp.inventory_movements_concept.description;
					if (resp.inventory_movements_concept.linked) {
						linked_to_fields.forEach(elem => elem.style.display = "block");
						// console.log(resp.inventory_movements_concept.linked)
						linked_to_field.value = resp.inventory_movements_concept.linked;
						$(linked_to_field).trigger("change")
						let options_linked = "<option></option>";
						if (resp.linked_models) {
							resp.linked_models.forEach( function(element, index) {
								options_linked += "<option value='"+element.id+"'>"+element.name+"</option>";
							});
						}
						name_linked_to_field.innerHTML = options_linked;
						name_linked_to_field.value = name_linked_to_field.dataset.selectedid;
						name_linked_to_field.dataset.selectedid = "";
					}else{
						linked_to_fields.forEach(elem => elem.querySelector("select").value = "");
					}
					$(name_linked_to_field).trigger("change")
					//si cambia de entrada o salida elimina los nested
					// console.log(old_concept_type +"---"+ resp.inventory_movements_concept.concept_type)
					if (old_concept_type != resp.inventory_movements_concept.concept_type) {
						removeNestedMovementProducts();
					}
					// si es salida pone el campo only_in_outputs
					if (concept_type_field.value == 'output') {
						document.querySelectorAll('[data-type="only_in_outputs"]').forEach((elem) =>{
							elem.classList.remove('d-none');
							console.log(code_key_field);
							if (code_key_field.value == 53) { elem.querySelector('select').required = true };
							if (code_key_field.value != 53) { elem.querySelector('select').required = false };
						});0
					}else {
						document.querySelectorAll('[data-type="only_in_outputs"]').forEach((elem) =>{
							elem.classList.add('d-none');
							elem.querySelector('select').required = false;
						})
					}
				}
			}).catch(error => {
				console.log(error);
				//si cambia de entrada o salida elimina los nested
				removeNestedMovementProducts();
			});
		}
		
		let removeNestedMovementProducts = () => {
			document.querySelectorAll(".nested-movement-products").forEach((el) => {
				el.remove();
			});
		}

		// limpiar precios en movimientos de inventarios nesteds
		let cleanPriceMovementNested = (elem) => {
			let parent = elem.closest(".nested-fields");
			parent.querySelector('input[data-type="quantity-nested-field"]').value = 1;
			parent.querySelector('input[data-type="warehouse_stock_id-nested').value = "";
			parent.querySelector('input[data-type="product_key_code-nested').value = "";
			parent.querySelector('input[data-type="product_description-nested').value = "";
			let price_nested_field = parent.querySelector('input[data-type="unit_cost-nested-field');
			price_nested_field.value = 0;
			price_nested_field.dataset.title = "0";
			price_nested_field.dispatchEvent(new Event("change"));
			let subtotal_nested_field = parent.querySelector('input[data-type="subtotal-nested-field');
			subtotal_nested_field.value = 0
			subtotal_nested_field.dispatchEvent(new Event("change"));
		}

		//seleccionar el precio en el modal de precios en movimientos al inventario
		let selectCostPrice = (id, elem) => {
			let parent = elem.closest("[data-type='group-price-change']");
			let price = elem.dataset.value;
			if (price == "free") {
				price = parent.querySelector('input[type="number"]').value;
			}
			//console.log(id)
			//console.log(price)
			let target_field = document.getElementById(id);
			target_field.value = price;
			target_field.dispatchEvent(new Event("change"));
			$("#sharedModal").modal("hide");
		}
		//cambiar los precios de producto al dar clic en el icono de cambiar
		let openModalChangePrice = (elem) => {
			// console.log(elem)
			let parent = elem.closest(".nested-fields");
			let price_nested_field = parent.querySelector("[data-type='unit_cost-nested-field']");
			// console.log(price_nested_field)
			let rdm_number = Math.round(Math.random() * 100000000000);
			price_nested_field.setAttribute('id', rdm_number);
			let concept_type = document.getElementById("concept_type_field").value;
			let concept_code_key = document.getElementById("concept_code_key_field").value;
			// console.log(concept_type);
			// console.log(concept_code_key);
			let warehouse_stock_id = parent.querySelector('[data-type="warehouse_stock_id-nested"]').value;
			// console.log(warehouse_stock_id);
			if (warehouse_stock_id) {
				fetch("/"+route_path_data_products+"/show_warehouse_stock?id="+warehouse_stock_id)
				.then( resp => resp.json())
				.then( product => {
					// console.log(product);
					// console.log(concept_type)
					let content = '';
					if (concept_type == "output" /*&& concept_code_key != "52" && concept_code_key != "57"*/) {
						// console.log('salida')
						content = "<div class='row'><div class='col-md-4 form-group field'>"+
						"<label>Precio Uno</label><div class='input-group' data-type='group-price-change'>"+
							"<input type='number' class='form-control' step='any' value='"+product.price_one+"' readonly='true'>"+
							"<div class='input-group-append' data-value='"+product.price_one+"' data-type='trigger-change-price-modal'><a class='input-group-text'>OK</a></div>"+
						"</div></div><div class='col-md-4 form-group field'>"+
						"<label>Precio Dos</label><div class='input-group' data-type='group-price-change'>"+
							"<input type='number' class='form-control' step='any' value='"+product.price_two+"' readonly='true'>"+
							"<div class='input-group-append' data-value='"+product.price_two+"' data-type='trigger-change-price-modal'><a class='input-group-text'>OK</a></div>"+
						"</div></div><div class='col-md-4 form-group field'>"+
						"<label>Precio Tres</label><div class='input-group' data-type='group-price-change'>"+
							"<input type='number' class='form-control' step='any' value='"+product.price_three+"' readonly='true'>"+
							"<div class='input-group-append' data-value='"+product.price_three+"' data-type='trigger-change-price-modal'><a class='input-group-text'>OK</a></div>"+
						"</div></div><div class='col-md-4 form-group field'>"+
						"<label>Precio libre</label><div class='input-group' data-type='group-price-change'>"+
							"<input type='number' class='form-control' step='any' value='"+product.price_one+"'>"+
							"<div class='input-group-append' data-value='free' data-type='trigger-change-price-modal'><a class='input-group-text'>OK</a></div>"+
						"</div></div></div>";
					}else if (concept_type == "input" /*&& concept_code_key != "2" && concept_code_key != "4"*/){
						content = "<div class='row'><div class='col-md-4 form-group field'>"+
						"<label>Costo</label><div class='input-group' data-type='group-price-change'>"+
							"<input type='number' class='form-control' step='any' value='"+product.cost+"' readonly='true'>"+
							"<div class='input-group-append' data-value='"+product.cost+"' data-type='trigger-change-price-modal'><a class='input-group-text'>OK</a></div>"+
						"</div></div><div class='col-md-4 form-group field'>"+
						"<label>Costo libre</label><div class='input-group' data-type='group-price-change'>"+
							"<input type='number' class='form-control' step='any' value='"+product.cost+"'>"+
							"<div class='input-group-append' data-value='free' data-type='trigger-change-price-modal'><a class='input-group-text'>OK</a></div>"+
						"</div></div></div>";
					}/*else {
						contenido = "En devluciones/cancelaciones no es posible cambiar el costo/precio.";
					}*/
					document.querySelector("#sharedModal .modal-title").innerHTML = "Cambiar " + (concept_type == "input" ? 'costo' : 'precio')
					document.querySelector("#sharedModal .modal-body").innerHTML = content;
					document.querySelector("#sharedModal .modal-footer").innerHTML = '';

					document.querySelectorAll("[data-type='trigger-change-price-modal']").forEach((el) => {
						el.addEventListener('click', () => {
							selectCostPrice(rdm_number, el)
						});
					});
					$("#sharedModal").modal("show");
				}).catch(err => {
					console.log(err);
				});;

			}else{
				alert("Selecciona un producto.");
			}
		}

		let document_field = document.getElementById('document_field');
		// calcular total al cambiar subtotales
		calculateTotalCostMovement = () => {
			let subtotal_nesteds = document.querySelectorAll('input[data-type="subtotal-nested-field"]');
			// console.log(subtts);
			let total = 0;
			// console.log(subtotal_nesteds)
			subtotal_nesteds.forEach(function(el) {
				if (!!( el.offsetWidth || el.offsetHeight || el.getClientRects().length )) {
					// console.log('visible');
					total += parseFloat(el.value||0);
				}
			});
			let total_field = document.getElementById("total_movement-field");
			if(total_field && (!document_field || document_field.value !== 'devolucion')) { total_field.value = total };
			// .log(total)
		}

		// calcular subtotal al cambiar precio y cantidad de los nesteds product_movements
		calculateSubtotalMovementNested = elem => {
			// console.log(elem.closest(".nested-fields"));
			let parent = elem.closest(".nested-fields");
			let quantity = parent.querySelector('input[data-type="quantity-nested-field"]').value;
			let price = parent.querySelector('input[data-type="unit_cost-nested-field"]').value;
			// console.log(quantity)
			let subtotal = (quantity || 0)*(price || 0);
			// console.log(subtotal)
			if (document_field && document_field.value == 'devolucion'){
				let porcent_discount_one = (document.getElementById("discount_percent-field").value || 0);
				let percent_iva = (document.getElementById("percent_iva_field").value || 0);
				let quantity_disc_one = (porcent_discount_one * subtotal) / 100;
				let quantity_iva = (percent_iva * subtotal) / 100;

				subtotal = subtotal - quantity_disc_one + quantity_iva
			}
			let subtotal_nested_field = parent.querySelector('input[data-type="subtotal-nested-field"]');
			subtotal_nested_field.value = subtotal;
			subtotal_nested_field.dispatchEvent(new Event("change"));
		}

		// dar clic en la lupa en movimientos al inventario
		let buscarProductoNested = (elem) => {
			let parent = elem.closest(".nested-fields");
			let rdm_number = Math.round(Math.random() * 100000000000);
			parent.setAttribute('data-id', rdm_number);

			document.getElementById("id_whom_open_modal").value = rdm_number; //set id whom open modal to modal
			
			let warehouse_id_field = parent.querySelector('select[data-type="warehouse_id-nested"]');
			let warehouse_id = warehouse_id_field.value
			let warehouse_name = warehouse_id_field.options[warehouse_id_field.selectedIndex].text;
			// console.log(warehouse_name);
			if (warehouse_name == "" || warehouse_name == null) {
				alert("Selecciona un almacén.");
			}else {
				document.getElementById("productsSearchModalTitle").innerHTML = ("Buscar productos en " + warehouse_name);
				$("#productsSearchModal").modal('show');

				document.getElementById("warehouse_search_products_input").value = warehouse_id;
				getJSONProducts();

				document.getElementById("search_products_input").addEventListener("input", (e) => {
					getJSONProducts();
				});
			}
		}

		let getJSONProducts = () => {
			let search_param = document.getElementById("search_products_input").value;

			let warehouse_id_to_search = document.getElementById("warehouse_search_products_input").value;

			let concept_type_field = document.getElementById("concept_type_field").value;

			fetch("/"+route_path_data_products+"/search_by_name.json?search="+search_param+'&&warehouse_id='+warehouse_id_to_search)
			.then(resp => resp.json())
			.then(resp => {
				// console.log(resp);
				let elements = "";
				resp.forEach((el) => {
					elements += "<tr><td>"+el.composed_key_code+"</td>"+
					"<td>"+el.description+(el.lots ? ' (por lotes)' : '')+"</td>"+
					"<td>"+el.stock+"</td>"+
					"<td>$"+(concept_type_field == 'output' ? el.price_one : el.cost)+"</td>"+
					"<td><a data-type='buttonSelectProductModal' data-product-id='"+el.id+"' data-cost='"+el.price_one+"' class='btn btn-primary'>+</a></td>"+
					"</tr>";
				});
				let bodyTable = document.getElementById("productSearchTableBody");
				bodyTable.innerHTML = elements;
				let buttonsSelect = bodyTable.querySelectorAll('[data-type="buttonSelectProductModal"]');
				buttonsSelect.forEach((el) => {
					el.addEventListener("click", (ev) => {
						//  console.log(el.dataset)
						selectProductInModal(el.dataset.productId, el.dataset.cost);
					});
				});
			}).catch(err => {
				console.log(err);
			});
		}

		//dar clic en la palomita para seleccionar el producto
		//cuando viene de otro documento pone el precio que ya tenia (lo recibe como parametro)
		selectProductInModal = (product_id, cost) => {
			// console.log(product_id);
			// es el que viene desde que se da clic en buscar para saber a que nested asignarlo
			let id_whom_open_modal = document.getElementById("id_whom_open_modal").value;
			// console.log(id_whom_open_modal);
			// identificar parent
			let parent = document.querySelector("tr.nested-fields[data-id='"+id_whom_open_modal+"']");
			// console.log(parent);
			// $(".loader").show();
			fetch("/"+route_path_data_products+"/show_warehouse_stock?id="+product_id)
			.then( resp => resp.json())
			.then( product => {
				// console.log(product);
				// asignar en nested
				// $(parent).find("input[data-type='product_key_code-nested']").val();
				// description del product en el input nested
				let description = product.description + " " + product.code_key_sub_one + " " + product.code_key_sub_two;
				var input = parent.querySelector("input[data-type='quantity-nested-field']");
				parent.querySelector("input[data-type='product_description-nested']").value = description;
				if (!input.value || parseInt(input.value) == 0) {
					input.value = 1;
				}
				parent.querySelector("input[data-type='product_description-nested']").setAttribute('title', description);
				// clave del product en el input nested
				parent.querySelector("input[data-type='product_key_code-nested']").value = product.composed_key_code;
				parent.querySelector("input[data-type='product_key_code-nested']").setAttribute('title', product.composed_key_code);
				// id del product en el input nested
				parent.querySelector("input[data-type='warehouse_stock_id-nested']").value = product.id;
				// precios
				let concept_type_field = document.getElementById("concept_type_field").value;
				let concept_code_key_field = document.getElementById("concept_code_key_field").value;
				// console.log(concept_type_field);
				// console.log(concept_code_key_field);
				let price_input = parent.querySelector("input[data-type='unit_cost-nested-field']");
				if (concept_type_field == "output") {
					// se asignan precio uno precio dos precio tres o precio con descuento de cliente
					price_input.value = product.price_one; 
					price_input.dataset.title = product.price_one;
				}else{
					//se asignan costo proveedor o se deja libre
					price_input.value = product.cost;
					price_input.dataset.title = product.cost;
				}
				price_input.dispatchEvent(new Event("change"));
				//mostrar ocultar agregar lotes
				if (product.lots) {
					// console.log("tiene lotes")
					parent.querySelector("[data-type='select-lot-btn-nested']").classList.remove("d-none");;
					parent.querySelector("[data-type='select-lot-btn-nested']").addEventListener("click", (ev) => {
						// console.log(ev)
						ev.preventDefault();
						openAddLotMovementNested(ev.target);
					});
				}else {
					parent.querySelector("[data-type='select-lot-btn-nested']").classList.add("d-none");;
				}
				$("#modal-buscar-productos").modal("hide");
				//poner el cost/precio que viene del otro documento
				if (cost) {
					//console.log(cost);
					$(parent).find("input[data-type='precio-nested']").val(cost).change();
				}
				removeLotsBox(parent);
				//cuando son devoluciones no se pone precio
				// if (concept_code_key_field == "52" || concept_code_key_field == "57" || concept_code_key_field == "2" || concept_code_key_field == "4") {
				// 	console.log(concept_code_key_field);
				// 	$(parent).find("input[data-type='precio-nested']").val("0").change();
				// }
				$("#productsSearchModal").modal('hide');
			}).catch(err => {
				console.log(err);
			});
		}	

		let removeLotsBox = (parent) => {
				parent.querySelector('[data-type="movements-lots-box"]').innerHTML = "";
				let btn_add_lot_nested = parent.querySelector('[data-type="select-lot-btn-nested"]');
				btn_add_lot_nested.classList.remove('btn-success')
				btn_add_lot_nested.classList.add('btn-warning');
		}

		//seleccionar lote para los productos que esten por lotes en el movimiento de inventario
		//dar clic en el icono +
		let openAddLotMovementNested = (elem) => {
			let btn_add_lot_nested = elem;
			//console.log(btn_add_lot_nested);

			// $(".loader").show();
			let parent = btn_add_lot_nested.closest(".nested-fields");

			removeLotsBox(parent);

			let id_rand_parent = parent.dataset.id;
			// console.log(parent);
			// console.log(id_rand_parent);

			let warehouse_stock_id = parent.querySelector('[data-type="warehouse_stock_id-nested"]').value;
			let product_key_code = parent.querySelector('[data-type="product_key_code-nested"]').value;
			let quantity = parent.querySelector('[data-type="quantity-nested-field"]').value;
			// console.log(warehouse_stock_id);
			let concept_type = document.getElementById("concept_type_field").value;
			let route_path_product_lots = document.getElementById("add-movement-product").dataset.lotsRoute;

			fetch("/"+route_path_product_lots+"/by_warehouse_stock?warehouse_stock_id="+warehouse_stock_id)
				.then(resp => resp.json())
				.then(resp => {
					// console.log(resp);
					let content = "<div class='row'><div class='field form-group col-md-6'><label>Lote</label>"+
					"<select id='product_lot_id_field-lot-modal' class='form-select' >"+
					"<option value=''>"+(concept_type == "input" ? "Crear nuevo" : "")+"</option>";
					resp.forEach(function (el) {
						content += "<option value='"+el.id+"' data-key-code='"+el.key_code+"' "+
						" data-production_date='"+el.production_date+"' data-expiration_date='"+el.expiration_date+"' "+
						"data-quantity='"+el.quantity+"'>"+el.key_code+"</option>";	
					});
					content += "</select></div>"+
					"<div class='col-md-6 field'><label>Clave</label><input type='text' class='form-control' id='key_code_field-lot-modal'></div>"+
					"<div class='col-md-6 field'><label>Fecha producción</label><input type='date' class='form-control' id='production_date_field-lot-modal'></div>"+
					"<div class='col-md-6 field'><label>Fecha caducidad</label><input type='date' class='form-control' id='expiration_date_field-lot-modal'></div>"+
					"<div class='col-md-6 field'><label>Cantidad</label><input type='text' class='form-control' readonly='true' id='quantity_field-lot-modal' data-value='"+quantity+"' value='"+quantity+"'></div>"+
					"<div class='col-md-6 field'><label>Tipo</label><input type='text' class='form-control' readonly='true' value='"+concept_type+"'></div>"+
					"</div>";
					//$("#id-nested").val(r);
					let buttons = "<br><a class='btn btn-primary pull-right' id='saveNestedLotInNestedBtn'>Aceptar</a>";
					document.querySelector("#sharedModal .modal-title").innerHTML = "Agregar lote " + product_key_code;
					document.querySelector("#sharedModal .modal-body").innerHTML = content;
					document.querySelector("#sharedModal .modal-footer").innerHTML = buttons;
					$("#sharedModal").modal("show");

					// callback al cambiar lote en select
					document.getElementById("product_lot_id_field-lot-modal").addEventListener("change", (ev) => {
						newOptionSelectLotModal(ev.target);
					});

					document.getElementById("saveNestedLotInNestedBtn").addEventListener("click", (ev) => {
						saveNestedLotInNested(id_rand_parent);
					});

					// // llenar el modal si ya existe un lote asignado en el nested
					// let lote_nested = $(parent).find('[data-type="nested-lote-mov"]');
					// let l_id = $(parent).find("[data-type='nested-lote-mov-producto_lote_id']").val();
					// let l_nom = $(parent).find("[data-type='nested-lote-mov-lote']").val();
					// let l_fp = $(parent).find("[data-type='nested-lote-mov-fecha_produccion']").val();
					// let l_fc = $(parent).find("[data-type='nested-lote-mov-fecha_caducidad']").val();
					// if (l_id != "") { //si esta seleccionado un lote exsitente 
					// 	$("#producto_lote_id-lote-modal").val(l_id).change();
					// }else {
					// 	$("#lote-lote-modal").val(l_nom);
					// 	$("#fecha_produccion-lote-modal").val(l_fp);
					// 	$("#fecha_caducidad-lote-modal").val(l_fc);
					// 	//$("#cantidad-lote-modal").val(text);
					// }
				}).catch(error => {
					console.log(error);
				});

		}

		//cambiar el select de lote en el modal de seleccionar lote producto de movimiento de inventario
		let newOptionSelectLotModal = (select) => {
			let selected_option = select.value;
			// console.log(selected_option);
			let code_key_lot_modal_field = document.getElementById("key_code_field-lot-modal");
			let production_date_lot_modal_field = document.getElementById("production_date_field-lot-modal");
			let expiration_date_lot_modal_field = document.getElementById("expiration_date_field-lot-modal");
			let quantity_lot_modal_field = document.getElementById("quantity_field-lot-modal");
			if (selected_option == "" || selected_option == undefined || selected_option == null) {
				// console.log('inexistente');
				code_key_lot_modal_field.removeAttribute('readonly');
				code_key_lot_modal_field.value = "";
				production_date_lot_modal_field.removeAttribute('readonly');
				production_date_lot_modal_field.value = '';
				expiration_date_lot_modal_field.removeAttribute('readonly');
				expiration_date_lot_modal_field.value = '';
				quantity_lot_modal_field.value = quantity_lot_modal_field.dataset.value;
			}else {
				// console.log("existente")
				let option_element_selected = select.options[select.selectedIndex]
				// console.log(option_element_selected.dataset)
				code_key_lot_modal_field.setAttribute("readonly", "true")
				code_key_lot_modal_field.value = option_element_selected.dataset.keyCode;
				production_date_lot_modal_field.setAttribute("readonly", "true")
				production_date_lot_modal_field.value = option_element_selected.dataset.productionDate;
				expiration_date_lot_modal_field.setAttribute("readonly", "true")
				expiration_date_lot_modal_field.value = option_element_selected.dataset.expirationDate;
				
				let legend = "";
				let sum = 0;
				if (document.getElementById("concept_type_field").value == "output") {
					sum = parseFloat(option_element_selected.dataset.quantity || 0) - parseFloat(document.getElementById("quantity_field-lot-modal").dataset.value || 0);
					legend = (option_element_selected.dataset.quantity + " - "+ document.getElementById("quantity_field-lot-modal").dataset.value + " = ");
				}else {
					sum = parseFloat(option_element_selected.dataset.quantity ||0)+parseFloat(document.getElementById("quantity_field-lot-modal").dataset.value || 0);
					legend = (option_element_selected.dataset.quantity + " + "+ document.getElementById("quantity_field-lot-modal").dataset.value + " = ");
				}
				
				document.getElementById("quantity_field-lot-modal").value = legend + sum;
			}
			
		}

		// al dar guardar en lote modal en form movimientos al inventario se guarda como nested para enviar
		let saveNestedLotInNested = (dataId) => {
			let parent = document.querySelector("[data-id='"+dataId+"']");
			let btn_add_lot_nested = parent.querySelector('[data-type="select-lot-btn-nested"]');
			btn_add_lot_nested.classList.remove('btn-warning')
			btn_add_lot_nested.classList.add('btn-success');
			//console.log(dataId)
			//console.log($(btn_add_lot_nested))
			let lot_id = document.getElementById("product_lot_id_field-lot-modal").value;
			let code_key_lot = document.getElementById("key_code_field-lot-modal").value;
			let production_date = document.getElementById("production_date_field-lot-modal").value;
			let expiration_date = document.getElementById("expiration_date_field-lot-modal").value;
			// time         = new Date().getTime()
			// regexp       = new RegExp($(this).data('link-to-add-field-id'), 'g')
			btn_add_lot_nested.setAttribute("title", "Cambiar lote")
			let fields_html = btn_add_lot_nested.dataset.linkToAddLot
				.replace("producto_lote_valor", lot_id)
				.replace("lote_valor", code_key_lot)
				.replace("fecha_produccion_valor", production_date)
				.replace("fecha_caducidad_valor", expiration_date);
			// console.log(fields_html);
			parent.querySelector('[data-type="movements-lots-box"]').innerHTML = fields_html;
			$("#sharedModal").modal("hide");
		}

		//-----------INICIALIZAR ELEMENTOS Y PONER CALLBACKS FORMULARIO DE MOVIMIENTOS AL INVENTARIO------------
		// Evento al editar algun producto
		let quantity_nested_field = document.querySelectorAll("[data-type='quantity-nested-field']");
		if (quantity_nested_field.length > 0){
			quantity_nested_field.forEach((quantity_field) => {
				quantity_field.addEventListener("change", (e) => {
					verifyLots(quantity_field)	;
				});
			})
		}


		verifyLots = (elem) => {
			let quantityValue = parseFloat(elem.value);
			let parent = elem.closest(".nested-fields");
			let productLotIdElement = parent.querySelector('input[data-tipo="nested-lote-mov-product_lot_id"]');
		
			if (productLotIdElement) {
				let productLotId = productLotIdElement.value;
		
				// Realizar la solicitud fetch para obtener la información del lote de productos
				fetch(`/lotes_de_productos/${productLotId}.json`)
					.then(response => response.json())
					.then(data => {
						// Verificar si la cantidad ingresada es menor o igual a la cantidad disponible
						if (quantityValue > data.quantity) {
							Swal.fire({
								icon: "error",
								title: "Oops...",
								html: `
    							    <p>El lote seleccionado no cuenta con las existencias requeridas.</p>
    							    <p>Seleccione máximo <b>${parseFloat(data.quantity)}</b> piezas.</p>
    							`,
							}).then((result) => {
								if (result.isConfirmed) {
									elem.value = data.quantity; // Restaura el valor al máximo disponible
									calculateSubtotalMovementNested(elem);
									calculateTotalCostMovement();
								}
							});
						} else { 
							calculateSubtotalMovementNested(elem);
							calculateTotalCostMovement();
						}
					})
					.catch(error => {
						console.error("Error al verificar el lote:", error);
					});
			} else {	
				calculateSubtotalMovementNested(elem);
				calculateTotalCostMovement();
			}	
		}	
		
		// console.log(document.getElementById('nesteds-product-movements'))
		// document.getByElementId('nesteds-product-movements').addEventListener('cocoon:after-insert', function(e, insertedItem, originalEvent) {
		$('#nesteds-product-movements').on('cocoon:after-insert', (e, insertedItem, originalEvent) => {
			// console.log('nested callback')
			const addButtonTop = document.getElementById('add-button-top');
			const addButtonBottom = document.getElementById('add-button-bottom');
			if (addButtonTop && addButtonBottom) {
				  addButtonTop.style.display = 'none';
				  addButtonBottom.style.display = 'block';
			  }
			let warehouse_id = document.getElementById("warehouse-id-field").value;
			// console.log(warehouse_id);
			// console.log(insertedItem[0])
			let warehouse_id_nested_field = insertedItem[0].querySelector("select[data-type='warehouse_id-nested']");
			// console.log(warehouse_id_nested_field)
			warehouse_id_nested_field.value = warehouse_id;

			// callback para calcular el total de los productos
			let quantity_nested_field = insertedItem[0].querySelector("[data-type='quantity-nested-field']");
			quantity_nested_field.addEventListener("change", (e) => {
				calculateSubtotalMovementNested(quantity_nested_field);
			});
			let	unit_cost_nested_field = insertedItem[0].querySelector("[data-type='unit_cost-nested-field']");
			unit_cost_nested_field.addEventListener("change", (e) => {
				calculateSubtotalMovementNested(unit_cost_nested_field);
			});

			// callback para calcular el subtotal general
			let subtotal_nested_field = insertedItem[0].querySelector('input[data-type="subtotal-nested-field"]');
			subtotal_nested_field.addEventListener("change", () => {
				calculateTotalCostMovement();
			});

			// callback reiniciar los valores cuando se cambia el almacen
			warehouse_id_nested_field.addEventListener("change", (e) => {
				cleanPriceMovementNested(e.target);
			});

			// onclick buscar producto
			let search_product_nested_trigger = insertedItem[0].querySelector("[data-type='trigger-search-product-nested']");
			// console.log(search_product_nested_trigger)
			search_product_nested_trigger.addEventListener("click", (e) => {
				buscarProductoNested(e.target);
			});

			// onclick cambiar precio del producto
			let change_price_nested_trigger = insertedItem[0].querySelector("[data-type='trigger-change-product-price-nested']");
			// console.log(change_price_nested_trigger)
			change_price_nested_trigger.addEventListener("click", (e) => {
				openModalChangePrice(e.target);
			});

			// si es traspaso bloquea el campo de almacen
			if (document.getElementById("warehouse_id_dos")) { // si es traspaso
				console.log('es traspaso')
				document.getElementById("warehouse-id-field").style.pointerEvents = 'none';
			}
			
		});

		$('#nesteds-product-movements').on('cocoon:after-remove', function (e, removedItem, originalEvent) {
			calculateTotalCostMovement();
			
			const addButtonTop = document.getElementById('add-button-top');
			const addButtonBottom = document.getElementById('add-button-bottom');
			const nestedItems = document.querySelectorAll('#nesteds-product-movements .nested-fields');
			
			if (nestedItems.length === 0) {
			  if (addButtonTop && addButtonBottom) {
				addButtonTop.style.display = 'block';
				addButtonBottom.style.display = 'none';
			  }
			}
		  });
		  

		//inicializar tipo de concepto para el movimeinto
		changeConceptMovement();
		code_key_field.addEventListener("change", (e) => {
			changeConceptMovement();
		});

		document.getElementById("add-movement-product").addEventListener("click", (event) => {
			let warehouse_id = document.getElementById("warehouse-id-field").value;
			let concept_type = document.getElementById("concept_type_field").value;
			if (!warehouse_id || !concept_type) {
				event.preventDefault();
				event.stopPropagation();
				alert("Selecciona un almacén y un concepto.");
			}
		});
	}

});